import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubmitLastStepMutation } from '../../../data/mutations/registration';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons';
import { CustomInput } from '../../common/inputs';

const RegistrationSummaryLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const buttonRef = useRef();

	const [name, setName] = useState<string>('');
	const [isNameRequired, setIsNameRequired] = useState<boolean>(false);

	const [password, setPassword] = useState<string>('');
	const [isPasswordRequired, setIsPasswordRequired] =
		useState<boolean>(false);
	const [passwordError, setPasswordError] = useState<boolean>(false);

	const [referralCode, setReferralCode] = useState<string>('');
	const [isReferralCodeRequired, setIsReferralCodeRequired] =
		useState<boolean>(false);

	const { mutateAsync, mutationError, isError, isPending } =
		useSubmitLastStepMutation(localStorage.getItem('returnHost') as string);

	const [emailError, setEmailError] = useState<boolean>(false);

	const [refError, setRefError] = useState<boolean>(false);

	useEffect(() => {
		if (
			isError &&
			(mutationError as any)?.data.target === 'name' //eslint-disable-line @typescript-eslint/no-explicit-any
		) {
			setEmailError(isError);
		}
		if (
			isError &&
			(mutationError as any)?.data.target === 'agentCode' //eslint-disable-line @typescript-eslint/no-explicit-any
		) {
			setRefError(isError);
		}
		if (
			isError &&
			(mutationError as any)?.data.target === 'password' //eslint-disable-line @typescript-eslint/no-explicit-any
		) {
			setPasswordError(isError);
		}
	}, [isError]);

	useEffect(() => {
		const refCode = localStorage.getItem('refCode');
		setReferralCode(
			refCode != undefined && refCode != null && refCode != 'null'
				? refCode
				: ''
		);
		setIsReferralCodeRequired(
			refCode != undefined && refCode != null && refCode != 'null'
				? refCode.length > 0
				: false
		);
	}, []);

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
		setEmailError(false);
		setIsNameRequired(e.target.value.length > 0);
	};

	const handleNameClear = () => {
		setName('');
		setEmailError(false);
		setIsNameRequired(false);
	};

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
		setPasswordError(false);
		setIsPasswordRequired(e.target.value.length > 0);
	};

	const handlePasswordPaste = (e: React.ClipboardEvent) => {
		const pastePassword = e.clipboardData.getData('text')[0];
		setPassword(pastePassword);
		setPasswordError(false);
		setIsPasswordRequired(pastePassword.length > 0);
	};

	const handlePasswordClear = () => {
		setPassword('');
		setPasswordError(false);
		setIsPasswordRequired(false);
	};

	const handleReferralCodeChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setReferralCode(e.target.value);
		setRefError(false);
		setIsReferralCodeRequired(e.target.value.length > 0);
	};

	const handleReferralCodeClear = () => {
		setReferralCode('');
		setRefError(false);
		setIsReferralCodeRequired(false);
	};

	const handleBackClick = () => {
		navigate(ROUTES.REGISTRATION.PHONE);
	};

	const handleSubmit = async () => {
		localStorage.setItem('refCode', referralCode);
		mutateAsync({
			name: name,
			password: password,
			referal: referralCode
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CustomButton
					isBack={true}
					onClick={handleBackClick}
					className={'backButton'}
				/>
				<h1 className={'nameAndPassword'}>
					{t('registrationName&Password')}
				</h1>
				<h1 className={'confirmCodeText'}>
					{t('registrationProvideNameAndPass')}
				</h1>
				<CustomInput
					autoComplete={'aszxcwewe'}
					isRequired={isNameRequired}
					type={'text'}
					id={'zxcasdqwe'}
					isError={emailError}
					label={t('registrationName')}
					value={name}
					onChange={handleNameChange}
					className={'nameInput'}
					onClearClick={handleNameClear}
					inputPropsRef={buttonRef}
				/>
				{emailError ? (
					<h1 className={'registrationEmailError'}>
						{
							(mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					<div className={'registrationEmailError'} />
				)}
				<CustomInput
					autoComplete={'strabe'}
					isError={passwordError}
					isRequired={isPasswordRequired}
					type={'password'}
					id={'asdzxcqwe'}
					label={t('registrationCreatePassword')}
					value={password}
					onChange={handlePasswordChange}
					onPaste={handlePasswordPaste}
					className={'passwordInput'}
					onClearClick={handlePasswordClear}
					inputPropsRef={buttonRef}
					isPassword={true}
				/>
				<h1 className={'createPasswordHelp'}>
					{t('registrationCreatePasswordHelp')}
				</h1>
				{passwordError ? (
					<h1 className={'registrationEmailError'}>
						{
							(mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					<div className={'registrationEmailError'} />
				)}
				<CustomInput
					inputPropsRef={buttonRef}
					isRequired={isReferralCodeRequired}
					type={'text'}
					id={'referralCode'}
					isError={refError}
					label={t('registrationRefCode')}
					value={referralCode}
					onChange={handleReferralCodeChange}
					className={'referralCodeText'}
					onClearClick={handleReferralCodeClear}
				/>
				{refError ? (
					<h1 className={'registrationEmailError'}>
						{
							(mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					<div className={'registrationEmailError'} />
				)}
				<CustomButton
					buttonRef={buttonRef}
					isLoading={isPending}
					text={t('registrationFinishButton') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'finishButton'}
					isDisabled={password.length === 0 || name.length == 0}
				/>
			</div>
		</div>
	);
};

export default RegistrationSummaryLayout;
