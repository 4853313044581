import { Helmet } from "react-helmet";
import { TITLES } from "../types/titles";
import RegistrationMaintainLayout from "../components/registration/maintain";

const RegistrationMaintain = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.MAINTAIN}</title>
			</Helmet>
			<RegistrationMaintainLayout />
		</>
	);
};

export default RegistrationMaintain;
