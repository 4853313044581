import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePinInput } from 'react-pin-input-hook';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons/index';
import { PinInput } from '../../common/inputs/index';
import {
	useAskNewPhoneCodeMutation,
	useSubmitPhoneCodeMutation
} from '../../../data/mutations/registration';

const RegistrationPhoneCodeLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const { askNewCode } = useAskNewPhoneCodeMutation();
	const { isError, mutateAsync, mutationError } =
		useSubmitPhoneCodeMutation(navigate);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setFakeError(false);
			setValues(values);
		},
		onComplete: (value: string) => {
			if (value.length === 6) {
				mutateAsync({ code: value });
			}
		},
		placeholder: ''
	});

	const [isResendCodeActive, setIsResendCodeActive] =
		useState<boolean>(false);
	const [counter, setCounter] = useState<number>(
		Number(sessionStorage.getItem('resendIn'))
	);

	const handleResendCode = async () => {
		askNewCode();
		setIsResendCodeActive(false);
		setCounter(Number(sessionStorage.getItem('resendIn')));
	};

	const handleBackClick = () => {
		navigate(ROUTES.REGISTRATION.PHONE);
	};

	useEffect(() => {
		if (isError) {
			setFakeError(true);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	useEffect(() => {
		if (counter == 0) {
			setIsResendCodeActive(true);
			setCounter(Number(sessionStorage.getItem('resendIn')));
		}
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CustomButton
					isBack={true}
					onClick={handleBackClick}
					className={'backButton'}
				/>
				<h1 className={'confirmEmailText'}>
					{t('registrationConfirmPhone')}
				</h1>
				<h1 className={'confirmCodeText'}>
					{t('registrationCode')}{' '}
					{(sessionStorage.getItem('phoneCode') as string) +
						'-' +
						sessionStorage.getItem('phone')?.replaceAll(' ', '-')}
				</h1>
				<div className={'codeInputBlock'}>
					<PinInput isError={fakeError} fields={fields} />
					{fakeError ? (
						<h1 className={'errorText'}>
							{
								(mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
							}
						</h1>
					) : (
						<div className={'errorText'} />
					)}
					{isResendCodeActive ? (
						<h1
							className={'resendCodeText'}
							onClick={handleResendCode}
						>
							{t('registrationResend')}
						</h1>
					) : (
						<h1 className={'resendCodeText-inactive'}>
							{t('registrationResendIn')} {counter}
						</h1>
					)}
				</div>
			</div>
		</div>
	);
};

export default RegistrationPhoneCodeLayout;
