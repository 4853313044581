import { Helmet } from "react-helmet";
import { TITLES } from "../types/titles";
import RegistrationPhoneLayout from "../components/registration/phone";
import { QueryClient } from "@tanstack/react-query";
import { useLoaderData } from "react-router-dom";
import { fetchRegistrationCountries } from "../api/registration";
import { TCountry, TDictionary } from "../types/types";

export const registrationPhoneLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ["registrationCountries"],
		queryFn: () => fetchRegistrationCountries("countries"),
		staleTime: Infinity,
	});
};

const RegistrationPhone = () => {
	const data = useLoaderData();

	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.PHONE}</title>
			</Helmet>
			<RegistrationPhoneLayout
				countries={
					(data as TDictionary)?.value.default?.filter(
						(country: unknown) => (country as TCountry).phoneCode != ""
					) as TCountry[]
				}
			/>
		</>
	);
};

export default RegistrationPhone;
