import { Helmet } from "react-helmet";
import { TITLES } from "../types/titles";
import RegistrationSummaryLayout from "../components/registration/summary";

const RegistrationSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.SUMMARY}</title>
			</Helmet>
			<RegistrationSummaryLayout />
		</>
	);
};

export default RegistrationSummary;
