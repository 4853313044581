import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { PHONE_CODES, TCountry, TModifiedCountry } from '../../../types/types';
import { CustomButton } from '../../common/buttons/index';
import { CustomInput } from '../../common/inputs';
import { CountryModal } from '../../common/modals';
import { useSubmitPhoneVerifyMutation } from '../../../data/mutations/registration';

interface Props {
	countries: TCountry[];
}
const RegistrationPhoneLayout = ({ countries }: Props) => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [phone, setPhone] = useState<string>('');
	const [isPhoneRequired, setIsPhoneRequired] = useState<boolean>(false);
	const [isPhoneDisabled, setIsPhoneDisabled] = useState<boolean>(false);
	const [phoneError, setPhoneError] = useState<boolean>(false);
	const [phoneInputError, setPhoneInputError] = useState<boolean>(false);
	const [phoneErrorFake, setPhoneErrorFake] = useState<boolean>(false);

	const { mutateAsync, mutationError, isError, isPending } =
		useSubmitPhoneVerifyMutation(navigate);

	const [countryCode, setCountryCode] = useState<
		TModifiedCountry | undefined
	>({
		code: t('registrationCodeCountry'),
		imgUrl: ''
	});

	const isPhoneButtonDisabled = () => {
		switch (countryCode?.code) {
			case '+375': {
				return !(
					countryCode?.code === '+375' &&
					PHONE_CODES.BY.includes(phone.substring(0, 2)) &&
					phone.replaceAll(' ', '').length === 9
				);
			}
			default: {
				return (
					phone.replaceAll(' ', '').length < 7 ||
					countryCode?.code === t('registrationCodeCountry')
				);
			}
		}
	};

	useEffect(() => {
		const sessionCode = sessionStorage.getItem('phoneCode');
		const sessionPhone = sessionStorage.getItem('phone');
		if (sessionCode && sessionPhone) {
			const foundSessionCountry = countries.find(
				(country: TCountry) => country.phoneCode === sessionCode
			);
			setCountryCode({
				code: foundSessionCountry?.phoneCode as string,
				imgUrl: foundSessionCountry?.link as string
			});
			setPhone(sessionPhone);
			setPhoneError(false);
			setPhoneInputError(false);
			setPhoneErrorFake(false);
		}

		const stateInfo = sessionStorage.getItem('stateInfo');
		if (stateInfo) {
			const stateInfoJson = JSON.parse(stateInfo);
			setIsPhoneDisabled(stateInfoJson.isPhoneVerified);

			const foundSessionCountry = countries.find(
				(country: TCountry) =>
					country.code === stateInfoJson.phoneCountryCode
			);

			setCountryCode({
				code:
					foundSessionCountry?.phoneCode != undefined
						? (foundSessionCountry?.phoneCode as string)
						: t('registrationCodeCountry'),
				imgUrl:
					foundSessionCountry?.link != undefined
						? (foundSessionCountry?.link as string)
						: ''
			});
			setPhone(
				stateInfoJson.phone.replace(foundSessionCountry?.phoneCode)
			);
		}
	}, []);

	useEffect(() => {
		if (isError) {
			setPhoneErrorFake(isError);
		}
	}, [isError]);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const buttonRef = useRef(null);

	const handlePhoneChange = (
		e: string | React.ChangeEvent<HTMLInputElement>
	) => {
		if (typeof e === 'string') {
			setPhoneErrorFake(false);
			setPhone(e as string);
			if(e.length > 0) {
				setPhoneError(
					countryCode?.code === '+375' &&
						!PHONE_CODES.BY.includes(e.substring(0, 2))
				);
			} else {
				setPhoneError(false);
			}
			setIsPhoneRequired(String(e).length > 0);
			switch (countryCode?.code) {
				case '+375': {
					if (e.length > 0) {
						setPhoneInputError(
							!(
								countryCode?.code === '+375' &&
								PHONE_CODES.BY.includes(e.substring(0, 2)) &&
								e.replaceAll(' ', '').length === 9
							)
						);
					} else {
						setPhoneInputError(false);
					}
					break;
				}
				default: {
					if (e.length > 0) {
						setPhoneInputError(
							e.replaceAll(' ', '').length <= 6 ||
								countryCode?.code ===
									t('registrationCodeCountry')
						);
					} else {
						setPhoneInputError(false);
					}
					break;
				}
			}
		}
	};

	const handlePhoneClear = () => {
		setPhone('');
		setIsPhoneRequired(false);
		setPhoneErrorFake(false);
	};

	const handleCountrySelect = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleCountryCodeChange = (value: {
		code: string;
		imgUrl: string;
	}) => {
		setCountryCode({
			code: value.code,
			imgUrl: value.imgUrl
		});
	};

	const handleBackClick = () => {
		navigate(ROUTES.REGISTRATION.PERSONAL);
	};

	const handleSubmit = () => {
		if (isPhoneDisabled) {
			navigate(ROUTES.REGISTRATION.SUMMARY);
			return;
		}
		mutateAsync({
			phone: countryCode?.code + phone.replaceAll(' ', ''),
			isPhoneVerified: isPhoneDisabled,
			countryCode: countries.find(
				(country: TCountry) => country.phoneCode === countryCode?.code
			)?.code as string
		});
		sessionStorage.setItem(
			'countryCode',
			countries.find(
				(country: TCountry) => country.phoneCode === countryCode?.code
			)?.code as string
		);
		sessionStorage.setItem('phoneCode', countryCode?.code as string);
		sessionStorage.setItem('phone', phone);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CountryModal
					countries={countries}
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleSelectClick}
					handleItemClick={handleCountryCodeChange}
				/>
				<CustomButton
					isBack={true}
					onClick={handleBackClick}
					className={'backButton'}
				/>
				<h1 className={'confirmEmailText'}>
					{t('registrationMobilePhone')}
				</h1>
				<h1 className={'confirmCodeText'}>
					{t('registrationProvideMobile')}
				</h1>
				<div className={'mobileInputBlock'}>
					<CustomInput
						isDisabled={isPhoneDisabled}
						isRequired={
							countryCode?.code != t('registrationCodeCountry')
						}
						type={'text'}
						id={'countryCodeSelect'}
						label={
							countryCode?.code != t('registrationCodeCountry')
								? (t('registrationCodeCountry') as string)
								: ''
						}
						value={countryCode?.code as string}
						onClearClick={handleCountrySelect}
						className={'countryCodeSelect'}
						isSelectCode={true}
						imgUrl={countryCode?.imgUrl as string}
					/>
					<CustomInput
						isError={
							phoneErrorFake || phoneError || phoneInputError
						}
						isDisabled={isPhoneDisabled}
						isRequired={isPhoneRequired}
						type={'text'}
						id={'number'}
						label={t('registrationPhone')}
						value={phone}
						onChange={handlePhoneChange}
						className={'phoneInput'}
						onClearClick={handlePhoneClear}
						placeHolder={'00-000-00-00'}
						isMobile={true}
						inputPropsRef={buttonRef}
					/>
				</div>
				{phoneErrorFake || phoneInputError || phoneError ? (
					<h1 className={'registrationEmailError'}>
						{
							phoneError || phoneInputError
								? t(
										phoneError
											? 'registrationBYPhoneCodeInvalid'
											: 'registrationPhoneInvalid'
								  )
								: (mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					<div className={'registrationEmailError'} />
				)}
				<CustomButton
					buttonRef={buttonRef}
					text={t('registrationStart') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'startButton'}
					isDisabled={isPhoneButtonDisabled()}
					isLoading={isPending}
				/>
			</div>
		</div>
	);
};

export default RegistrationPhoneLayout;
