import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import RegistrationPersonalLayout from '../components/registration/personal';

const RegistrationPersonal = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.PERSONAL}</title>
			</Helmet>
			<RegistrationPersonalLayout />
		</>
	);
};

export default RegistrationPersonal;
