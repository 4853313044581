import React, { ChangeEvent, MutableRefObject, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { PinInputFieldProps } from 'react-pin-input-hook';
import { IMaskInput } from 'react-imask';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VisibilityOffIcon, VisibilityOnIcon } from '../icons';

interface PinInputProps {
	fields: PinInputFieldProps[];
	isError: boolean;
}

interface CustomInputProps {
	autoComplete?: string;
	isRequired: boolean;
	type: string;
	id: string;
	label: string;
	value: string;
	onPaste?: (e: React.ClipboardEvent) => void;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onClearClick?: () => void;
	className: string;
	placeHolder?: string;
	isMobile?: boolean;
	searchInput?: boolean;
	isSelectCode?: boolean;
	isSelectCountry?: boolean;
	imgUrl?: string;
	isPassword?: boolean;
	isDateOfBirth?: boolean;
	isInternalSelect?: boolean;
	isDisabled?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inputPropsRef?: MutableRefObject<any>;
	isInternal?: boolean;
	handleModalOpen?: () => void;
	themeNumber?: boolean;
	isBankTransfer?: boolean;
	onKeyUp?: () => void;
	isWithdraw?: boolean;
	isAddress?: boolean;
	isError?: boolean;
	isInputError?: boolean;
	isInternalWithoutChev?: boolean;
	isNonCloseIcon?: boolean;
	isPattern?: boolean;
}

const CustomInput = ({
	isRequired,
	type,
	id,
	label,
	value,
	onChange,
	className,
	onPaste,
	onClearClick,
	isError,
	placeHolder,
	isDisabled,
	inputPropsRef,
	isNonCloseIcon,
	isSelectCode,
	isMobile,
	imgUrl,
	isPassword,
	autoComplete
}: CustomInputProps) => {
	if (isSelectCode) {
		const { t } = useTranslation();
		return (
			<div className={className} onClick={onClearClick}>
				<TextField
					inputMode={'decimal'}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& input.Mui-disabled': {
							WebkitTextFillColor: '#181A21 !important'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled': {
								color: '#181A21'
							},
							'& input.Mui-disabled': {
								color: '#181A21'
							},
							'.MuiInputBase-input.Mui-disabled': {
								WebkitTextFillColor: '#848790',
								color: '#848790'
							},
							'& fieldset': {
								borderColor: '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor: '#F6F6F8'
							}
						},
						'.MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: '#848790',
							color: '#848790'
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: '#F6F6F8',
						borderColor: '#0159A2'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom:
								imgUrl != '' &&
								value != t('registrationCodeCountry')
									? '2px'
									: '16.5px',
							marginLeft:
								imgUrl === '' &&
								value === t('registrationCodeCountry')
									? '0'
									: '10px'
						},
						inputMode: 'decimal',
						returnkeytype: 'go'
					}}
					InputProps={{
						readOnly: true,
						style: {
							borderColor: '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
							paddingLeft: '0'
						},
						endAdornment:
							imgUrl === '' &&
							value === t('registrationCodeCountry') ? (
								<InputAdornment position='end'>
									<IconButton
										onClick={onClearClick}
										aria-label=''
										edge='end'
									>
										<ExpandMoreIcon className='countryCodeChev' />
									</IconButton>
								</InputAdornment>
							) : (
								''
							),
						startAdornment:
							imgUrl != '' ? (
								<InputAdornment position='end'>
									<IconButton
										sx={{
											paddingBottom: '0px !important',
											paddingTop: '13px !important'
										}}
										onClick={onClearClick}
										aria-label=''
										edge='end'
									>
										<img
											className={'selectImg'}
											src={imgUrl}
											alt={value}
										/>
									</IconButton>
								</InputAdornment>
							) : (
								''
							)
					}}
					onClick={onClearClick}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isPassword) {
		const [showPassword, setShowPassword] = useState<boolean>(false);

		const handleClickShowPassword = () => setShowPassword((show) => !show);

		const handleMouseDownPassword = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			event.preventDefault();
		};

		return (
			<div className={className}>
				<TextField
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled': {
								color: '#181A21'
							},
							'& input.Mui-disabled': {
								color: '#181A21'
							},
							'.MuiInputBase-input.Mui-disabled': {
								WebkitTextFillColor: '#848790',
								color: '#848790'
							},
							'& fieldset': {
								borderColor: '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor: '#F6F6F8'
							}
						},
						'.MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: '#848790',
							color: '#848790'
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: isError ? '#FDEDEB' : '#F6F6F8'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						autoComplete: autoComplete,
						style: {
							color: '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor: '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge='end'
								>
									{showPassword ? (
										<VisibilityOnIcon />
									) : (
										<VisibilityOffIcon />
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
					type={showPassword ? 'text' : 'password'}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isMobile) {
		return (
			<div className={className}>
				<TextField
					disabled={isDisabled}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled': {
								color: '#181A21'
							},
							'& input.Mui-disabled': {
								color: '#181A21'
							},
							'.MuiInputBase-input.Mui-disabled': {
								WebkitTextFillColor: '#848790',
								color: '#848790'
							},
							'& fieldset': {
								borderColor: '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor: '#F6F6F8'
							}
						},
						'.MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: '#848790',
							color: '#848790'
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: isError ? '#FDEDEB' : '#F6F6F8',
						borderColor: '#0159A2'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor: '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						inputComponent: isMobile ? PhoneInput : undefined
					}}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	return (
		<div className={className}>
			<TextField
				disabled={isDisabled}
				fullWidth
				sx={{
					'& label.Mui-focused': {
						transform: 'translate(14px, 5px) scale(0.75)'
					},
					'& label.Mui-required': {
						transform: 'translate(14px, 5px) scale(0.75)'
					},
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							borderColor: '#00BEB4 !important',
							borderWidth: '1px'
						},
						'&.Mui-disabled fieldset': {
							borderColor: '#F6F6F8 !important',
							borderWidth: '1px'
						},
						'&.Mui-disabled': {
							color: '#181A21'
						},
						'& input.Mui-disabled': {
							color: '#181A21'
						},
						'.MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: '#848790',
							color: '#848790'
						},
						'& fieldset': {
							borderColor: '#F6F6F8'
						},
						'&:hover fieldset': {
							borderColor: '#F6F6F8'
						}
					},
					'.MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor: '#848790',
						color: '#848790'
					},
					fieldset: {
						legend: {
							maxWidth: '0.01px'
						}
					},
					borderRadius: '12px !important',
					background: isError ? '#FDEDEB' : '#F6F6F8',
					borderColor: '#0159A2'
				}}
				placeholder={placeHolder}
				InputLabelProps={{
					required: isRequired,
					style: {
						color: '#848790',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px'
					}
				}}
				inputProps={{
					autoComplete:
						type === 'email'
							? 'none'
							: autoComplete
							? autoComplete
							: 'off',
					style: {
						color: '#181A21',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px',
						paddingBottom: '2px'
					},
					onKeyPress: (event) => {
						const { key } = event;
						if (key === 'Enter') {
							inputPropsRef?.current.focus();
						}
					},
					returnkeytype: 'go'
				}}
				InputProps={{
					style: {
						borderColor: '#F6F6F8',
						borderRadius: '12px',
						height: '60px'
					},
					endAdornment: isNonCloseIcon ? (
						''
					) : (
						<InputAdornment position='end'>
							<IconButton onClick={onClearClick} edge='end'>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M12.0002 19.1667C8.31826 19.1667 5.3335 16.1819 5.3335 12.5C5.3335 8.8181 8.31826 5.83334 12.0002 5.83334C15.682 5.83334 18.6668 8.8181 18.6668 12.5C18.6668 16.1819 15.682 19.1667 12.0002 19.1667ZM12.0002 11.5572L10.5859 10.143C10.3256 9.88263 9.90349 9.88263 9.64314 10.143C9.38279 10.4033 9.38279 10.8254 9.64314 11.0858L11.0574 12.5L9.64315 13.9142C9.38279 14.1745 9.38279 14.5967 9.64314 14.857C9.90349 15.1174 10.3256 15.1174 10.5859 14.857L12.0002 13.4428L13.4144 14.857C13.6747 15.1173 14.0968 15.1173 14.3572 14.857C14.6175 14.5967 14.6175 14.1746 14.3572 13.9142L12.943 12.5L14.3572 11.0858C14.6175 10.8254 14.6175 10.4033 14.3572 10.143C14.0968 9.88263 13.6747 9.88263 13.4144 10.143L12.0002 11.5572Z'
										fill='#848790'
									/>
								</svg>
							</IconButton>
						</InputAdornment>
					)
				}}
				onPaste={onPaste}
				type={type}
				size='medium'
				id={id}
				label={label}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PhoneInput = React.forwardRef((props: any, ref: any) => {
	React.useImperativeHandle(ref, () => ({
		focus: () => {
			ref.current.focus();
		}
	}));

	return (
		<IMaskInput
			{...props}
			mask='0000000000000'
			definitions={{
				'#': /[1-9]/
			}}
			onAccept={props.onChange}
			overwrite={false}
		/>
	);
});

const PinInput = ({ fields, isError }: PinInputProps) => {
	return (
		<div className={'pin_input'}>
			{fields.map((propsField, idx) => (
				<input
					key={idx}
					className={
						'pin_input__field' +
						(isError ? ' pin_input__field-error' : '')
					}
					{...propsField}
				/>
			))}
		</div>
	);
};

export { CustomInput, PinInput, PhoneInput };
