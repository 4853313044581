import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import RegistrationLayout from '../components/registration';
import { useEffect } from 'react';

/*export const registrationRootLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ["registrationStart"],
		queryFn: () => fetchRegistrationStart("Personal"),
		staleTime: Infinity,
	});
};*/

const Root = () => {
	useEffect(() => {
		localStorage.setItem(
			'returnHost',
			new URLSearchParams(window.location.search).get(
				'returnHost'
			) as string
		);
		localStorage.setItem(
			'refCode',
			new URLSearchParams(window.location.search).get('refCode') as string
		);
	});

	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.INDEX}</title>
			</Helmet>
			<RegistrationLayout />
		</>
	);
};

export default Root;
