export const ROUTES = {
	REGISTRATION: {
		INDEX: "/:locale/registration",
		PERSONAL: "/:locale/registration/personal",
		EMAIL_CODE: "/:locale/registration/personal/code",
		PHONE: "/:locale/registration/personal/phone",
		PHONE_CODE: "/:locale/registration/personal/phone/code",
		SUMMARY: "/:locale/registration/personal/summary",
		MAINTAIN: "/:locale/registration/maintain",
	},
};
