import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useSubmitClearMutation,
	useSubmitFirstStepMutation
} from '../../../data/mutations/registration';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { CustomButton } from '../../common/buttons/index';
import { CustomInput } from '../../common/inputs';
import RegistrationCheckboxes from './registration.checkboxes';
import {
	TCheckbox,
	TCheckboxes,
	TMappedCheckboxes
} from '../../../types/types';

const RegistrationPersonalLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [email, setEmail] = useState<string>('');
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);
	const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [data, setData] = useState<TCheckboxes>(
		sessionStorage.getItem('sessionCheckboxesBack')
			? JSON.parse(
					sessionStorage.getItem('sessionCheckboxesBack') as string
			  )
			: undefined
	);

	const { mutateAsync, isPending, isError, mutationError } =
		useSubmitFirstStepMutation(navigate, isEmailDisabled);

	const { mutateClearAsync } = useSubmitClearMutation(navigate);

	const [fakeError, setFakeError] = useState(false);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
		}
	}, [isError]);

	useEffect(() => {
		setIsLoading(true);
		const sessionCheckboxesBack = sessionStorage.getItem(
			'sessionCheckboxesBack'
		);
		const sessionEmail = sessionStorage.getItem('email');
		const sessionCheckboxes = sessionStorage.getItem('checkBoxes');
		const stateInfo = sessionStorage.getItem('stateInfo');
		if (sessionCheckboxesBack) {
			setData(JSON.parse(sessionCheckboxesBack));
		}
		if (sessionEmail) {
			setEmail(sessionEmail);
			setIsEmailRequired(true);
		}
		if (sessionCheckboxes) {
			setIsChecked(JSON.parse(sessionCheckboxes).checkBoxes);
		}
		if (stateInfo) {
			const stateInfoJson = JSON.parse(stateInfo);
			setIsEmailDisabled(stateInfoJson.isEmailVerified);
		}
		setIsLoading(false);
	}, []);

	const [isChecked, setIsChecked] = useState<boolean[]>([
		false,
		false,
		false
	]);

	const buttonRef = useRef(null);

	if (isLoading) {
		return <></>;
	}

	const handleBackClick = async () => {
		await mutateClearAsync();
	};

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setIsEmailRequired(e.target.value.length > 0);
		setFakeError(false);
	};

	const handleEmailClear = () => {
		if (!isEmailDisabled) {
			setEmail('');
			setIsEmailRequired(false);
			setFakeError(false);
		}
	};

	const handleIsChecked = () => {
		return data?.checkBoxes.some(
			(checkbox, idx) =>
				checkbox.isRequired && checkbox.isRequired != isChecked[idx]
		);
	};

	const handleSubmit = async () => {
		sessionStorage.setItem('email', email);
		sessionStorage.setItem(
			'checkBoxes',
			JSON.stringify({
				checkBoxes: isChecked
			})
		);
		await mutateAsync({
			email: email,
			checkBoxes: data?.checkBoxes
				.filter(
					(checkbox: TCheckbox) => checkbox.checkBoxId != 'cookie'
				)
				.map(
					//eslint-disable-next-line
					(checkbox: any, idx: number) =>
						(checkbox = {
							checkBoxId: checkbox.checkBoxId,
							isSelected: isChecked[idx]
						})
				) as TMappedCheckboxes,
			isEmailVerified: isEmailDisabled
		});
	};

	return (
		<>
			<div className={'component'}>
				<div className={'innerComponent'}>
					<CustomButton
						isBack={true}
						onClick={handleBackClick}
						className={'backButton'}
					/>
					<h1 className={'welcomeText'}>
						{t('registrationWelcomeText')}
					</h1>
					<CustomInput
						isError={fakeError}
						isDisabled={isEmailDisabled}
						isRequired={isEmailRequired}
						type={'email'}
						id={'email'}
						label={t('registrationProvideEmail')}
						value={email}
						onChange={handleEmailChange}
						className={'registrationInput'}
						onClearClick={handleEmailClear}
						inputPropsRef={buttonRef}
					/>
					<RegistrationCheckboxes
						isError={false}
						setIsChecked={setIsChecked}
						checked={isChecked}
					/>
					{fakeError ? (
						<h1 className={'registrationEmailError'}>
							{
								(mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
							}
						</h1>
					) : (
						<div className={'registrationEmailError'} />
					)}
					<CustomButton
						buttonRef={buttonRef}
						text={t('registrationStart') as string}
						type={'submit'}
						onClick={handleSubmit}
						className={'startButton'}
						isDisabled={
							handleIsChecked() ||
							email.length === 0 ||
							!new RegExp(
								/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
							).test(email)
						}
						isLoading={isPending}
					/>
				</div>
			</div>
		</>
	);
};

export default RegistrationPersonalLayout;
