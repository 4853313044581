import { useContext } from "react"
import { LocaleContext } from "../data/locale.provider"
import { useNavigate } from "react-router-dom";

export const useCustomNavigate = () => {
    const {locale} = useContext(LocaleContext);
    const navigate = useNavigate();

    return function (path: string) {
        navigate(path.replace(":locale", locale));
    }
}