import React, { createContext } from "react";

export const LocaleContext = createContext({
	locale: "ru",
} as {
	locale: "ru" | "en";
});

const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
	const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

	return <LocaleContext.Provider value={{ locale }}>{children}</LocaleContext.Provider>;
};

export default LocaleProvider;
