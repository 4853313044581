export const TITLES = {
	REGISTRATION: {
		INDEX: "Registration",
		PERSONAL: "Personal Account | Registration",
		LEGAL: "Legal Account | Registration",
		EMAIL: "Email Code | Registration",
		PHONE: "Phone | Registration",
		PHONE_CODE: "Phone Code | Registration",
		SUMMARY: "Summary | Registration",
		DATA: "Data | Registration",
		MAINTAIN: "Maintain | Registration",
	},
};
