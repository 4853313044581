export const API_ROUTES = {
	DICTIONARY_BY_ID: {
		METHOD: "GET",
		URL: "/api/v1/user/registration/dictionaries/{dictId}",
	},
	REGISTRATION_START: {
		METHOD: "POST",
		URL: "/api/v1/user/registration/start",
	},
	REGISTRATION_INFO: {
		METHOD: "GET",
		URL: "/api/v1/user/registration/info",
	},
	GET_EULA: {
		METHOD: "GET",
		URL: "/api/v1/user/registration/eula",
	},
	SEND_EULA: {
		METHOD: "POST",
		URL: "/api/v1/user/registration/eula",
	},
	SEND_CODE: {
		METHOD: "POST",
		URL: "/api/v1/user/registration/send",
	},
	VERIFY_CODE: {
		METHOD: "POST",
		URL: "/api/v1/user/registration/verify",
	},
	CREATE_ACCOUNT: {
		METHOD: "POST",
		URL: "/api/v1/user/registration/create",
	},
	CLEAR: {
		METHOD: "POST",
		URL: "/api/v1/user/registration/clear"
	}
};
