import React, { useEffect, useState } from 'react';
import { CustomCheckbox } from '../../common/checkboxes';

interface Props {
	checked: boolean[];
	isError: boolean;
	setIsChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
}
const RegistrationCheckboxes = ({ checked, setIsChecked, isError }: Props) => {
	//eslint-disable-next-line
	const [mappedEula, setMappedEula] = useState<any>();

	useEffect(() => {
		setMappedEula(
			JSON.parse(sessionStorage.getItem('checkBoxesText') as string)
		);
	},[]);

	const handleCheckBoxChange = (isChecked: boolean, idx: number) => {
		const localChecked = [...checked];
		localChecked[idx] = isChecked;
		setIsChecked(localChecked);
	};

	return (
		<div className={'checkboxes'}>
			{mappedEula?.checkBoxes?.map(
				//eslint-disable-next-line
				(checkBox: any, idx: number) => (
					<div
						className={
							idx === 0 ? 'firstCheckBox' : 'secondCheckBox'
						}
					>
						<CustomCheckbox
							isError={isError}
							key={idx}
							checked={checked[idx]}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => handleCheckBoxChange(e.target.checked, idx)}
						/>
						<h1
							className={'checkBox-h1'}
							dangerouslySetInnerHTML={{
								__html: checkBox?.questionValue
									.replaceAll('/n', '')
									.replaceAll(
										'href',
										"target='_blank' rel='noreferrer noopener' href"
									)
							}}
						></h1>
					</div>
				)
			)}
		</div>
	);
};

export default RegistrationCheckboxes;
