import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import i18n from './locale/i18n';
import './styles/index.css';
import { ROUTES } from './types/routes';
import Root from './routes/root.route';
import LocaleProvider from './data/locale.provider';
import RegistrationPersonal from './routes/registration-personal.route';
import RegistrationEmailCode from './routes/registration-email-code.route';
import RegistrationPhone, {
	registrationPhoneLoader
} from './routes/registration-phone.route';
import RegistrationPhoneCode from './routes/registration-phone-code.route';
import RegistrationSummary from './routes/registration-summary.route';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RegistrationMaintain from './routes/registration-maintain.route';

const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

const router = createBrowserRouter([
	{
		path: '/',

		element: <Root />
	},
	{
		path: ROUTES.REGISTRATION.INDEX,
		element: <Root />
	},
	{
		path: ROUTES.REGISTRATION.PERSONAL,
		element: <RegistrationPersonal />
	},
	{
		path: ROUTES.REGISTRATION.EMAIL_CODE,
		element: <RegistrationEmailCode />
	},
	{
		path: ROUTES.REGISTRATION.PHONE,
		loader: registrationPhoneLoader,
		element: <RegistrationPhone />
	},
	{
		path: ROUTES.REGISTRATION.PHONE_CODE,
		element: <RegistrationPhoneCode />
	},
	{
		path: ROUTES.REGISTRATION.SUMMARY,
		element: <RegistrationSummary />
	},
	{
		path: ROUTES.REGISTRATION.MAINTAIN,
		element: <RegistrationMaintain />
	}
]);

const queryClient = new QueryClient();

const App = () => {
	useEffect(() => {
		i18n.changeLanguage(locale);
	}, []);

	return (
		<div id={'wrapper'} className={'wrapper'}>
			<QueryClientProvider client={queryClient}>
				<LocaleProvider>
					<RouterProvider router={router} />
				</LocaleProvider>
			</QueryClientProvider>
		</div>
	);
};

export default App;
