import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { CustomButton, RegistrationButton } from '../common/buttons/index';
import { useSelectAccountMutation } from '../../data/mutations/registration';

const RegistrationLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const { mutateAsync } = useSelectAccountMutation(navigate);

	const handleFirstButtonClick = () => {
		mutateAsync({
			accType: 'Personal'
		});
	};
	const handleSecondButtonClick = () => {
		mutateAsync({
			accType: 'Corporate'
		});
	};

	const handleBackClick = () => {
		if (window.top) {
			const returnHost =
				(new URLSearchParams(window.location.search).get(
					'returnHost'
				) as string) != ''
					? (new URLSearchParams(window.location.search).get(
							'returnHost'
					  ) as string)
					: (localStorage.getItem('returnHost') as string);
			window.top.location.href = returnHost;
		}
	};

	return (
		<>
			<div className={'component'}>
				<div className={'innerComponent'}>
					<CustomButton
						isBack={true}
						onClick={handleBackClick}
						className={'backButton'}
					/>
					<h1 className={'welcomeText'}>
						{t('registrationWelcomeText')}
					</h1>
					<h1 className={'secondaryText'}>
						{t('registrationChooseAccountType')}
					</h1>
					<RegistrationButton
						onClick={handleFirstButtonClick}
						imgSrc='/firstButtonIcon.png'
						text={t('registrationFirstButtonText')}
					/>
					<RegistrationButton
						onClick={handleSecondButtonClick}
						imgSrc='/secondButtonIcon.png'
						text={t('registrationSecondButtonText')}
					/>
				</div>
			</div>
		</>
	);
};

export default RegistrationLayout;
