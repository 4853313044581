import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { CustomButton } from "../../common/buttons/index";
import { ROUTES } from "../../../types/routes";
import { WhaleMaintainIcon } from "../../common/icons";

const RegistrationMaintainLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const buttonRef = useRef(null);

	const handleRefreshClick = () => {
		navigate(ROUTES.REGISTRATION.INDEX);
	};

	return (
		<>
			<div className={"component"}>
				<div className={"innerComponent"}>
					<WhaleMaintainIcon className={""} />
					<h1 className={"maintainHeaderText"}>{t("registrationMaintainHeader")}</h1>
					<h1 className={"maintainHeaderBody"}>{t("registrationMaintainBody")}</h1>
					<CustomButton
						buttonRef={buttonRef}
						text={t("registrationMaintainButton") as string}
						type={"submit"}
						onClick={handleRefreshClick}
						className={"startButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default RegistrationMaintainLayout;
