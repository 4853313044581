export type TCountry = {
	code: string;
	name: string;
	link: string;
	phoneCode: string;
	isRestricted: boolean;
};

export type TDictionary = {
	dicid: string;
	name: string;
	version: string;
	description: string;
	value: {
		default: unknown[];
	};
};

export type TModifiedCountry = {
	code: string;
	imgUrl: string;
};

export type TApiRoutes = {
	[key: string]: {
		METHOD: "GET" | "POST";
		URL: string;
	};
};

export type TAccountType = "Corporate" | "Personal";

export type TInfoData = {
	currentStepId: string;
	path: string[];
	isEulaAccepted: boolean;
	checkBoxesResult: [];
	email: string;
	isEmailVerified: boolean;
	phone: string;
	isPhoneVerified: boolean;
	currentExpiresCodeIn: number;
	currentResendCodeIn: number;
	numberLeftAttemptsVerify: number;
};

export type TCheckbox = {
	checkBoxId: string;
	isRequired: boolean;
};

export type TMappedCheckbox = {
	checkBoxId: string;
	isSelected: boolean;
};

export type TEulaTextCheckBox = {
	checkBoxId: {
		agreement: string;
		personal: string;
		cookie: string;
	};
};

export type TEulaCheckBoxKeys = "personal" | "agreement" | "cookie";

export type TCheckboxes = {
	checkBoxes: TCheckbox[];
};

export type TMappedCheckboxes = TMappedCheckbox[];

export const PHONE_CODES = {
	BY: ["29", "33", "44", "25"],
};
